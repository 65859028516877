import React from "react"
import { graphql } from "gatsby"
import ProductCardList from "../components/product-card-list"
import Layout from "../components/layout"
import HeroSection from "../components/HeroSection"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {
  const products = data.Directus.Product.reverse() || [];
  return (
    <Layout>
      <Seo title="Principais livros sobre Liderança e Gestão" />
      <HeroSection />
      {/* <p>No canal Liderança e Gestão Online você encontrará dicas e resumos sobre os melhores livros de desenvolvimento pessoal e profissional, liderança, gestão de pessoas, empreendedorismo e muito mais.</p>
      <p>
        Por meio da indicação de livros escritos por grandes nomes da literatura nacional e internacional, conheça as principais referências com foco em liderança e gestão para o seu desenvolvimento pessoal e profissional.
      </p> */}
      <ProductCardList products={products} />
    </Layout>
  );
}

export const query = graphql`
    query NewestBooks {
        Directus {
            Product(sort: "Id") {
                Name
                Slug
                MetaDescription
                Image {
                    filename_disk
                }
                SubCategoryId {
                    Slug
                    Name
                    CategoryId {
                        Name
                        Slug
                    }
                }
            }
        }
    }
    `;

export default IndexPage
