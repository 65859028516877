import React from "react"
import { Link } from "gatsby"
function ProductCard({ product }) {
    return (
        <div className="md:w-3/12">
            <div className="rounded overflow-hidden shadow-lg mr-5 mb-5">
                <Link className="center" to={`/${product.SubCategoryId.CategoryId.Slug}/${product.SubCategoryId.Slug}/${product.Slug}`} >
                    <img className="w-full object-center" src={`https://cms.wesolveit.com.br/assets/${product.Image.filename_disk}?fit=cover&width=291&height=381&quality=80`} alt={`Capa do livro ${product.Name}`} />
                </Link>
                <div className="px-6 py-4">
                    <Link to={`/${product.SubCategoryId.CategoryId.Slug}/${product.SubCategoryId.Slug}/${product.Slug}`} title={product.Name} >
                        <div className="font-bold text-xl mb-2 line-clamp-2">{product.Name}</div>
                    </Link>
                    <p className="text-gray-700 text-base line line-clamp-6">
                        {product.MetaDescription}
                    </p>
                </div>
                <div className="px-6 pt-4 pb-2">
                    <Link to={`/${product.SubCategoryId.CategoryId.Slug}`} >
                        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{product.SubCategoryId.CategoryId.Name}</span>
                    </Link>
                    <Link to={`/${product.SubCategoryId.CategoryId.Slug}/${product.SubCategoryId.Slug}/`} >
                        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{product.SubCategoryId.Name}</span>
                    </Link>
                    {/* <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#fundos-de-investimentos-imobiliarios</span> */}
                </div>
            </div>
        </div>
    );
}

export default ProductCard