import React from "react"
import ProductCard from "./product-card";

function ProductCardList({products}){
    if (products === undefined || products.length === 0)
        return null;

    return (
        <div className="flex flex-col md:flex-row flex-wrap">
            {products.map(i => <ProductCard product={i} />)}
            {/* {products.map(i => <ProductCard product={i} />)}
            {products.map(i => <ProductCard product={i} />)}
            {products.map(i => <ProductCard product={i} />)}
            {products.map(i => <ProductCard product={i} />)} */}
        </div>
    );
}

export default ProductCardList